import axios from '@/configs/http/internal-api-axios'

const baseUrl = 'api/v1/dashboard'

async function getCostByYear(year) {
  const url = `${baseUrl}/cost?year=${year}`
  const response = await axios.get(url)
  if (response.data) {
    return response.data
  }
}

async function getNumberUserUseApp() {
  const url = `${baseUrl}/apps-users`
  const response = await axios.get(url)
  if (response.data) {
    return response.data
  }
}

async function getListDepartment() {
  const response = await axios.get('api/v1/company/department-list')
  if (response.data) {
    return response.data.data.items
  }
}

async function getDataDashboardFooter(year, department_id) {
  const url = `${baseUrl}/cost-department?year=${year}&department_id=${department_id}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    price: response.data.price,
    users: response.data.users,
  }
}

async function getApps() {
  const url = `${baseUrl}/apps`
  const response = await axios.get(url)
  if (response.data) {
    return response.data.data
  }
}

export const dashboardCustomerService = {
  getCostByYear,
  getNumberUserUseApp,
  getListDepartment,
  getDataDashboardFooter,
  getApps,
}
