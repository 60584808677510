<template>
  <div class="DBHeader">
    <div class="row align-items-stretch">
      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between DBHeader__card-title">
              <span>費用</span>
              <router-link
                v-if="!isCustomerUser"
                :to="{ name: 'list-of-cost', query: { selectedViewCost: 1 } }"
                >詳細を見る<em class="mdi mdi-chevron-right"
              /></router-link>
            </div>
            <div class="DBHeader__card-image">
              <img src="@/assets/icons/dashboard-customer-money.svg" alt="logo" />
              <h1>{{ totalCost }}円</h1>
              <div class="float-end">
                <div class="input-group input-group-sm">
                  <b-select v-model="selected" :options="years" @change="selectedChange" />
                  <label class="input-group-text">年</label>
                </div>
              </div>
            </div>
            <v-chart :options="optionChart1" />
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between DBHeader__card-title">
              <span>利用中のサービス</span>
              <router-link v-if="!isCustomerUser" to="/customer/saas-management"
                >詳細を見る<em class="mdi mdi-chevron-right"
              /></router-link>
            </div>
            <div class="DBHeader__card-image">
              <img src="@/assets/icons/dashboard-customer-service-logo.svg" alt="logo" />
            </div>
            <div class="d-flex flex-wrap justify-content-around DBHeader__card-detail">
              <div v-for="item of services" :key="item.id" class="DBHeader__item">
                <div class="card mini-stats-wid brand_item-content">
                  <div class="col-xl-6">
                    <div class="media">
                      <div class="me-3 DBHeader__item-img">
                        <img :src="item.src" alt="" />
                      </div>
                      <div class="media-body">
                        <h5 class="mb-1 DBHeader__item-name">
                          {{ item.name }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between DBHeader__card-title">
              <span>サービス利用中のアカウント数</span>
              <router-link :to="{ name: 'employee-management', params: { saasConnected: 1 } }"
                >詳細を見る<em class="mdi mdi-chevron-right"
              /></router-link>
            </div>
            <div class="DBHeader__card-image DBHeader__card-image__sub">
              <img src="@/assets/icons/dashboard-customer-user-circle.svg" alt="logo" />
              <h1>{{ totalUser }}</h1>
            </div>
            <v-chart :options="optionChart2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import { dashboardCustomerService } from '@/services/dashboard-customer-service'
import { getYearsOptions } from '@/utils/date-utils'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardCustomerHeader',
  components: { 'v-chart': ECharts },
  data() {
    return {
      title: 'DASHBOARD',
      optionChart1: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)',
          position: function (pos) {
            // pos is pointer position
            // display tooltip base on this pos
            return {
              left: pos[0] - 100,
              top: pos[1],
            }
          },
        },
        legend: {
          orient: 'horizontal',
          icon: 'circle',
          y: 270,
          x: 0,
          padding: 0,
          selectedMode: false,
        },
        color: ['#556EE6', '#F46A6A', '#F1B44C', '#50A5F1', '#34C38F', '#74788D', '#343A40'],
        series: [
          {
            name: '',
            y: -50,
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      optionChart2: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'horizontal',
          icon: 'circle',
          y: 270,
          padding: 0,
          selectedMode: false,
        },
        color: ['#556EE6', '#F46A6A', '#F1B44C', '#50A5F1', '#34C38F', '#74788D', '#343A40'],
        series: [
          {
            name: '',
            y: -50,
            type: 'pie',
            radius: ['50%', '70%'],
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                padding: 0,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold',
                },
              },
            },
            data: [],
          },
        ],
      },
      selected: new Date().getFullYear(),
      services: [],
      totalCost: null,
      totalUser: null,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['apps']),
    ...mapGetters('user', ['isCustomerUser']),
    years() {
      return getYearsOptions()
    },
  },
  async created() {
    await this.getCost()
    if (this.optionChart1.series[0].data.length) {
      this.optionChart1.series[0].name = '累計'
    }
    let getNumberUserUseApp = await dashboardCustomerService.getNumberUserUseApp()
    this.services = (await dashboardCustomerService.getApps())
      .filter((item) => item.subscribe_app_status === 1)
      .map((result) => ({ id: result.id, name: result.name, src: result.logo }))
    this.optionChart2.series[0].data = getNumberUserUseApp.data.data.map((item) => ({
      value: item.users,
      name: item.app_name,
    }))
    if (this.optionChart2.series[0].data.length) {
      this.optionChart2.series[0].name = '累計'
    }
    this.totalUser = getNumberUserUseApp.data.total
  },
  methods: {
    async selectedChange() {
      await this.getCost()
    },
    async getCost() {
      let getCostByYear = await dashboardCustomerService.getCostByYear(this.selected)
      this.optionChart1.series[0].data = getCostByYear.data.data.map((item) => ({
        value: item.price,
        name: item.name,
      }))
      this.totalCost = getCostByYear.data.total
    },
  },
}
</script>

<style scoped></style>
