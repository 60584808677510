<template>
  <div v-if="created" class="DBFooter">
    <div class="card">
      <div class="card-body">
        <div class="float-end">
          <div class="input-group input-group-sm">
            <b-select
              v-model="selectedYear"
              class="DBFooter__dropdown-group text-truncate"
              :options="years"
              @change="changeData()"
            />
            <label class="input-group-text">年</label>
          </div>
        </div>
        <div class="float-end">
          <div class="input-group input-group-sm">
            <b-select
              v-model="selectedChart"
              class="DBFooter__dropdown text-truncate"
              :options="selectChart"
              @change="changeData()"
            />
          </div>
        </div>
        <div class="float-end">
          <div class="input-group input-group-sm">
            <b-form-select
              v-model="selectedRoom"
              class="DBFooter__dropdown text-truncate"
              :options="listDepartment"
              @change="changeData()"
            />
          </div>
        </div>

        <div class="float-end">
          <h3 class="DBFooter__text">
            {{ textFooter }}
          </h3>
        </div>
        <h4 class="card-title mb-4 DBFooter__title">
          {{ titleChart }}
        </h4>
        <LineChart v-if="flag" :series="seriesLine" :chart-options="chartOptionsLine" />
        <ColumnChart v-else :series="seriesColumn" :chart-options="chartOptionsColumn" />
        <div v-if="!isCustomerUser" class="float-end">
          <router-link :to="{ name: 'department-list' }"
            >詳細を見る<em class="mdi mdi-chevron-right"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/pages/dashboard-customer/line-chart'
import ColumnChart from '@/pages/dashboard-customer/column-chart'
import { dashboardService } from '@/services/data-service'
import { dashboardCustomerService } from '@/services/dashboard-customer-service'
import { getYearsOptions } from '@/utils/date-utils'
import { mapGetters } from 'vuex'
export default {
  components: { LineChart, ColumnChart },
  data() {
    return {
      flag: false,
      created: false,
      dataCreated: false,
      isActive: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      selectedChart: 1,
      selectedRoom: '',
      listDepartment: [],
      selectChart: [
        { value: 1, text: '費用' },
        { value: 2, text: 'アカウント数' },
      ],
      textFooter: '',
      titleChart: '部署毎の費用',
      seriesLine: [],
      chartOptionsLine: dashboardService.getOptionLineChartDBCustomer(),
      seriesColumn: [],
      chartOptionsColumn: dashboardService.getOptionColumnChart(),
    }
  },
  computed: {
    ...mapGetters('user', ['isCustomerUser']),
    years() {
      return getYearsOptions()
    },
  },
  async created() {
    const department = await dashboardCustomerService.getListDepartment()
    this.listDepartment = [
      ...[{ value: '', text: '全部署' }],
      ...department.map((item) => ({ value: item.id, text: item.name })),
    ]
    const { price } = await dashboardCustomerService.getDataDashboardFooter(
      this.selectedYear,
      this.selectedRoom
    )
    this.seriesColumn.push({ name: '部署毎の費用', data: price })
    const totalPrice = price.reduce((a, b) => a + b)
    this.textFooter = '年間累計額: ' + totalPrice + '円'
    this.created = true
  },
  methods: {
    async changeData() {
      const { price, users } = await dashboardCustomerService.getDataDashboardFooter(
        this.selectedYear,
        this.selectedRoom
      )
      if (this.selectedChart === this.selectChart[0].value) {
        this.titleChart = '部署毎の費用'
        this.seriesColumn = []
        this.seriesColumn.push({ name: '部署毎の費用', data: price })
        const totalPrice = price.reduce((a, b) => a + b)
        this.textFooter = '年間累計額: ' + totalPrice + '円'
        this.flag = false
      }
      if (this.selectedChart === this.selectChart[1].value) {
        this.titleChart = '部署毎のアカウント数'
        this.seriesLine = []
        this.seriesLine.push({ name: '利用企業数', data: users })
        const totalUsers = users.reduce((a, b) => a + b)
        this.textFooter = 'アカウント数: ' + totalUsers + 'アカウント'
        this.flag = true
      }
    },
  },
}
</script>

<style scoped lang="scss">
.text-footer {
  margin-right: 100px;
  color: #50a5f1;
}
.DBFooter__dropdown {
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  height: 27px;
  max-width: 100px;
}
</style>
