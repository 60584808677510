<template>
  <Layout>
    <PageHeader :title="title" />
    <dashboard-customer-header />
    <div class="row">
      <div class="col-12">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="col-lg-12">
              <dashboard-customer-footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '../../layouts/main/app'
import PageHeader from '../../layouts/vertical/page-header'
import DashboardCustomerHeader from './dashboard-customer-header'
import DashboardCustomerFooter from './dashboard-customer-footer'
import { mapGetters } from 'vuex'
import router from '@/router'

export default {
  name: 'DashboardCustomer',
  components: {
    DashboardCustomerFooter,
    DashboardCustomerHeader,
    PageHeader,
    Layout,
  },
  data() {
    return {
      title: 'ダッシュボード',
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser', 'switchLoginEnable', 'isSwitched']),
  },
  created() {
    if (this.switchLoginEnable && !this.isSwitched) {
      router.push({
        name: 'dashboard',
      })
    }
  },
}
</script>

<style></style>
