<template>
  <div :style="{ height: '410px' }">
    <apexchart
      class="apex-charts"
      type="bar"
      dir="ltr"
      height="360"
      :series="series"
      :options="chartOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'ColumnChart',
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped></style>
